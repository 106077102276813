import {CustomRequestStatus} from "@/constants/request/CustomRequestConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export interface CustomRequestDTO {
    id: number;
    status: CustomRequestStatus
    title: string;
    description: string;
    assigneeId: number;
    assigneeName: string;
    createdById: number;
    createdByName: string;
    expirationDate: Date;
    comments: CustomRequestCommentDTO[];
    files: Array<FileMetaDTO>;
}

export class CustomRequestCommentDTO{
    comment!: string;
    leftById!: number;
    leftByName!: string;
    createDate!: Date;
    files: Array<FileMetaDTO> = [];
}

export class UpdateCustomRequestDTO{
    status!: CustomRequestStatus;
}

export class CreateCustomRequestCommentPayload {
    comment!: string;
    files: Array<FileMetaDTO> = [];
}