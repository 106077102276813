import CreateCustomRequestPayload from "@/dto/request/custom/CreateCustomRequestPayload";
import axios from "axios";
import {REQUEST_CUSTOM_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {
    CreateCustomRequestCommentPayload,
    CustomRequestDTO,
    UpdateCustomRequestDTO
} from "@/dto/request/custom/CustomRequestDTO";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";

class CustomRequestService {

    create(payload: CreateCustomRequestPayload){
        return axios.post(REQUEST_CUSTOM_ENDPOINT, payload, {headers: authHeader()});
    }

    getRequest(id: number){
        return axios.get<CustomRequestDTO>(REQUEST_CUSTOM_ENDPOINT + `/${id}`, {headers: authHeader()});
    }

    addComment(id: number, payload: CreateCustomRequestCommentPayload){
        return axios.post(REQUEST_CUSTOM_ENDPOINT + `/${id}/comments`, payload, {headers: authHeader()});
    }

    updateRequest(id: number, payload: UpdateCustomRequestDTO){
        return axios.put(REQUEST_CUSTOM_ENDPOINT + `/${id}`, payload, {headers: authHeader()});
    }

}

export default new CustomRequestService();