









































import {Vue, Component} from 'vue-property-decorator';
import CustomRequestService from "@/services/request/CustomRequestService";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import {
  CreateCustomRequestCommentPayload,
  CustomRequestDTO,
  UpdateCustomRequestDTO
} from "@/dto/request/custom/CustomRequestDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import {CustomRequestStatus} from "@/constants/request/CustomRequestConstants";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

@Component({
  computed: {
    CustomRequestStatus() {
      return CustomRequestStatus
    }
  },
  components: {SingleFileHolder, PortalTextarea, PortalInput}
})
export default class CustomRequest extends Vue {

  request: CustomRequestDTO | null = null;

  documentToAdd: FileMetaDTO | null = null;

  mounted(): void {
    this.loadRequest()
  }

  loadRequest(): void {
    Application.startLoading();
    CustomRequestService.getRequest(this.id).then(
        ok => {
          this.request = ok.data;
          if (this.request && this.request.comments) {
            this.request.comments.sort((a, b) =>
                new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
            );
          }
          Application.stopLoading();
        },
        err => processError(err, this)
    )
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

  payload: CreateCustomRequestCommentPayload = new CreateCustomRequestCommentPayload()

  updateStatus(status: CustomRequestStatus): void {
    Application.startLoading();
    let payload = new UpdateCustomRequestDTO;
    payload.status = status
    CustomRequestService.updateRequest(this.id, payload).then(
        () => {
          Application.stopLoading()
          this.loadRequest()
        },
        err => processError(err, this)
    )
  }

  addDocument(file: FileMetaDTO): void {
    this.payload.files.push(file);
    this.documentToAdd = null;
  }

  addComment(): void {
    if (this.payload) {
      Application.startLoading();
      CustomRequestService.addComment(this.id, this.payload).then(
          ok => {
            Application.stopLoading()
            this.loadRequest()
            this.payload = new CreateCustomRequestCommentPayload()
          },
          err => processError(err, this)
      )
    }
  }


}
